import { webComponents, type PluginOptions } from "@tolemac/web-components"

export default defineNuxtPlugin(app => {
    app.vueApp.use(webComponents, {
        translate(key) { return key },
        // emitter
        dev: true,
        register: [
            't-button',
            't-button-icon',
            't-input',
            't-datetime',
            't-input-password',
            't-dropdown',
            't-select',
            't-switch',
            't-checkboxes',
            't-checkbox'
        ]
    } as PluginOptions)
})