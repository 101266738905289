import { acceptHMRUpdate, defineStore } from 'pinia'
import { jwtDecode, type JwtPayload } from 'jwt-decode'
import { useStoreTrainee } from './store.trainee'

import { IAccount, IToken, TokenRight } from '@/api/myedvenn/account'

import { navGuardLoginUrl } from '@/middleware/public.global'

type Token = JwtPayload & IToken

type AccountState = {
  account: null | IAccount;

  token: null | {
    access: string;
    accessDecoded: Token;
    refresh: string;
    refreshDecoded: Token;
  }
}

export const useStoreAccount = defineStore('account', {

  state: () => {
    const localStorageState = JSON.parse(localStorage?.getItem('state') || '{}');
    console.debug(`[account]: localStorageState reload.`, localStorageState)

    return {
      account: null,
      token: null,
      ...localStorageState
    } as AccountState
  },

  getters: {
    isInternal: state => state.token?.accessDecoded?.rights?.indexOf(TokenRight.INTERNAL) !== -1 || false,
    isTrainee: state => state.token?.accessDecoded?.rights?.indexOf(TokenRight.INTERNAL) === -1 || false,
    isLogged: state => !!state.token
  },

  actions: {

    async login(email: string, password: string) {
      this.account = await this.$api.account.login({ email, password });
      if (this.isTrainee)
        await useStoreTrainee().refresh();
    },

    setToken(accessToken: string, refreshToken?: string) {
      if (accessToken) accessToken = accessToken.replace(/^Bearer /, '')
      if (refreshToken) refreshToken = refreshToken.replace(/^Bearer /, '')

      if (!accessToken && !refreshToken) this.token = null
      else this.token = {
        access: accessToken,
        refresh: refreshToken || this.token?.refresh || null,
        accessDecoded: accessToken ? jwtDecode<JwtPayload>(accessToken) : null,
        refreshDecoded: refreshToken ? jwtDecode<JwtPayload>(refreshToken) : (this.token?.refreshDecoded || null)
      }
    },

    async refresh() {
      if (this.token && !this.isInternal) {
        try {
          this.account = await this.$api.account.getMe({})
          if (this.isTrainee)
            await useStoreTrainee().refresh();
        } catch (e) {
          await this.logout()
        }
      }
    },

    async logout() {
      try {
        await this.$api.account.logout({})
      } finally {
        const redirect = navGuardLoginUrl()
        if (redirect)
          this.$router.push(redirect)
        else
          this.$router.go(0)
      }
    },

    async logoutTo() {
      try {
        await this.$api.account.logout({})
      } finally {
        const redirect = navGuardLoginUrl(this.$router.currentRoute.value?.fullPath || window.location.pathname)
        if (redirect)
          this.$router.push(redirect)
        else
          this.$router.go(0)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreAccount, import.meta.hot))
}