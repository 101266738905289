import { differenceInHours } from "date-fns";

import { issueReport } from '@/plugins/01.logger'
import type { ITrainingPeriod } from "@/api/myedvenn/training";

/** start include - end include */
export function countDays(end: number | Date, start: number | Date = new Date().getTime()) {
    return Math.round(differenceInHours(end, start) / 24) || 0;
}

export function checkPassword(password: string, confirm: string) {
    if (!password)
        return null
    if (password.length < 8)
        return '8 caractères minimum'
    if (!/.*[0-9].*/.test(password))
        return 'au moins 1 chiffre'
    if (!/.*[A-Z].*/.test(password))
        return 'au moins une majuscule'
    if (!/.*[a-z].*/.test(password))
        return 'au moins une minuscule'
    if (password !== confirm)
        return 'confirmation incorrecte'
}

export function modulesIsWIP(start: number, end: number) {
    const now = new Date().getTime()

    if (!start) return false
    if (!end) return true
    if (start > end) return false
    return now < (end - 8640000)
}

export enum TrainingStatus {
    NEXT = 1,
    WIP = 2,
    ENDED = 3,
}

export function trainingStatus(periods: ITrainingPeriod[]): TrainingStatus {
    const now = new Date().getTime()

    let status = TrainingStatus.NEXT
    periods.forEach((p, pi) => {
        if (pi === (periods.length - 1) && now > p.endAt) status = TrainingStatus.ENDED
        else if (now > p.startAt && now < p.endAt) status = TrainingStatus.WIP
    })
    return status
}

/**
 * @param findedClass add this class on the finded element
 * @param findedClassDuration remove findedClass after this duration
 */
export function scrollTo(hash: string, findedClass?: string, findedClassDuration: number = 80) {

    let i = 0
    const DELAY = 50
    const MAX_LOOP = 100

    function _scrollTo() {
        if (i > MAX_LOOP) return

        const elt = document.getElementById(hash)
        if (elt) {
            elt.scrollIntoView({ behavior: 'smooth' })
            elt.classList.add(findedClass)
            setTimeout(() => {
                elt.classList.remove(findedClass)
            }, findedClassDuration)
        } else {
            i += 1
            setTimeout(_scrollTo, DELAY, hash)
        }
    }

    _scrollTo()
}

export function mailtoSupport(email = '', error: any = '') {

    let link = `mailto:Support - My Edvenn<contact-project+edvenn-tootela-support@incoming.gitlab.com>?subject=Support - My Edvenn - Issue&body=`
    let body = `- description du problème:
    <veuillez préciser>
    
- adresse email (celle de votre compte my.edvenn):
    ${email || '<veuillez préciser>'}

- heure: ${new Date().toString()}
- timezone: ${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone} 
- navigateur: ${navigator?.userAgent}
- lang: ${navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language}
- url: ${window?.location?.href}
- version: ${useRuntimeConfig().public.version || ''}
- écran: ${window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth}w / ${window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight}h`

    // add error
    if (error)
        body += `
- erreur: ${error}`

    // add logs
    body += `
- logs:
`

    for (const log of issueReport.logs.reverse()) {
        if ((body.length + log.length) > 2000) {
            body += `    ...
`
            break
        }
        body += `    ${log}
`
    }

    return link + encodeURIComponent(body)
}