<template>
  <div class="bg-gray-50 py-1">
    <div v-if="training && training.intervals.length" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

      <header>

        <!-- name / date -->
        <h1 class="pt-12 text-3xl font-bold leading-tight text-center text-gray-900">{{ training.name }}</h1>
        <p class="text-center text-gray-500">
          {{ $filter.dateFormat(training.intervals[0].startAt) }} -
          {{ $filter.dateFormat(training.intervals[training.intervals.length - 1].endAt) }}
        </p>

        <!-- summary -->
        <div class="mx-auto mt-8 mb-12 sm:w-2/3 relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs">
          <div
            class="text-gray-500 hover:text-gray-700 rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center">
            <div class="text-blue font-bold text-3xl">{{ training.state.assignsSubmitted || 0 }}</div>
            <div>Corrections en attente</div>
          </div>
          <div
            class="text-gray-500 hover:text-gray-700 rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center">
            <div class="text-blue font-bold text-3xl">
              {{ ((training.state.completed || 0) * 100).toFixed(0) }} %
            </div>
            <div>Réalisé</div>
          </div>
        </div>

        <!-- progress bar -->
        <TrainingProgress :training="training" class="my-12" />

        <!-- menu -->
        <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
          <NuxtLink v-for="(tab, tabIdx) in tabs" :key="tab.name" :to="tab.href" custom
            v-slot="{ href, route, navigate, isActive, isExactActive }">
            <a :href="href" @click="navigate"
              :class="[isExactActive ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-20']"
              :aria-current="isExactActive ? 'page' : undefined">
              <span v-if="$store.display.breakpoint.mdAndUp">
                {{ tab.name }}
                <span v-if="tab.waitingAttendance"
                  class="h-2 w-2 inline-block align-middle rounded-full bg-orange-600"></span>
              </span>
              <component v-else :is="tab.icon" class="h-7 w-7 mx-auto" />
              <span aria-hidden="true"
                :class="[isExactActive ? 'bg-blue' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
            </a>
          </NuxtLink>
        </nav>
      </header>

      <main class="py-12">
        <NuxtPage :training="training" />
      </main>

    </div>
  </div>
</template>


<script lang="ts">
import { AcademicCapIcon, CalendarIcon, ClockIcon, DocumentTextIcon } from '@heroicons/vue/24/solid'

import type { ITraining } from '@/api/myedvenn/training'

import { scrollTo } from '@/util'

export default defineComponent({
  setup() {
    const { $store, $router, $routeParser } = useNuxtApp()

    const trainingId = computed(() => $routeParser.params.int.get('id'))

    const training = ref<ITraining>(null)

    watch(() => $store.trainee.trainings[trainingId.value]?.parts !== undefined, trainingLoaded => {
      if (trainingLoaded) {

        training.value = $store.trainee.trainings[trainingId.value];

        if ($router.currentRoute.value.hash)
          scrollTo($router.currentRoute.value.hash.replace(/^#/, ''), 'animate-pulse', 4000)

      }
    }, { immediate: true, flush: 'post' })

    return {
      training,

      tabs: computed(() => [
        { name: 'Ma formation', icon: AcademicCapIcon, href: `/training/${trainingId.value}` },
        { name: 'Mon calendrier', icon: CalendarIcon, href: `/training/${trainingId.value}/calendar` },
        { name: 'Mes émargements', icon: ClockIcon, href: `/training/${trainingId.value}/attendance`, waitingAttendance: training.value.state.waitingAttendance },
        { name: 'Mon contrat', icon: DocumentTextIcon, href: `/training/${trainingId.value}/contract` }
      ])
    }
  }
})
</script>