import { CommentSource, CommentContactedBy } from "@/api/myedvenn/comment";
import { AttendanceStatus } from '@/api/myedvenn/attendance';

// TokenRight
// CommentContactedBy
// FileLinkEntity
// ErrorCode
// ContactTitle
// DocumentType
// ReportType
// TrainingAssignStatus
// UserRole

export default defineNuxtPlugin(() => ({
    provide: {
        enum: {
            CommentContactedBy: enumDisplay(CommentContactedBy, {
                [CommentContactedBy.UNDEFINED_CommentCatgory]: '',
                [CommentContactedBy.EMAIL]: 'Mail',
                [CommentContactedBy.INTERNE]: 'Interne',
                [CommentContactedBy.MAIL]: 'Courrier',
                [CommentContactedBy.MODULE_END]: 'Fin de module',
                [CommentContactedBy.PHONE]: 'Tél.',
                [CommentContactedBy.MARKETING]: 'Marketing',
                [CommentContactedBy.REVIEW]: 'Correction',
            }),
            CommentSource: enumDisplay(CommentSource, {
                [CommentSource.UNDEFINED_CommentSource]: '',
                [CommentSource.GLOBAL]: 'global',
                [CommentSource.PACKAGE_HISTORY]: 'PACKAGE_HISTORY',
                [CommentSource.PACKAGE_ADMINISTRATIVE]: 'PACKAGE_ADMINISTRATIVE',
                [CommentSource.PACKAGE_ATTENDANCES]: 'PACKAGE_ATTENDANCES',
                [CommentSource.PACKAGE_TRACKING]: 'PACKAGE_TRACKING',
            }),
            AttendanceStatus: enumDisplay(AttendanceStatus, {
                [AttendanceStatus.UNDEFINED_AttendanceStatus]: '',
                [AttendanceStatus.DRAFT]: 'DRAFT',
                [AttendanceStatus.DRAFT__WAITING]: 'DRAFT__WAITING',
                [AttendanceStatus.TO_BE_VALIDATED]: 'TO_BE_VALIDATED',
                [AttendanceStatus.REJECTED]: 'REJECTED',
                [AttendanceStatus.RECEIVED]: 'RECEIVED',
                [AttendanceStatus.RECEIVED__ARCHIVED]: 'RECEIVED__ARCHIVED',
            })
        }
    }
}))

function enumDisplay<T>(enumm: T, translation: Record<number, string>) {
    const ordinal = enumOrdinal(enumm)
    return {
        ...enumm,

        get values() { return ordinal },

        translate(o?: number | string) {
            if (o === undefined) return ''

            const enumKey = typeof o === 'number' ? o : (enumm as any)[o] as number

            return translation[enumKey]
        }

    } as T & {
        values: number[];
        translate: (v?: number | string) => string;
    }
}

function enumOrdinal(enumm: any) {
    return Object.keys(enumm).filter(key => !isNaN(Number(key))).map(v => parseInt(v)).filter(x => !!x)
}