import { defineNuxtRouteMiddleware, useNuxtApp } from "#app"
import { RouteLocationNormalized } from 'vue-router'

import { issueReport } from '@/plugins/01.logger'
import { TokenRight } from "@/api/myedvenn/account"

const PUBLIC_ROUTES = ['admin', 'welcome', 'login', 'password_reset']

const ADMIN_ROUTES = [
    {
        name: 'Administratif',
        menu: true,
        right: TokenRight.INVOICE,
        href: '/admin/administrative',
        items: [
            { name: "Émargements à valider", href: "/admin/administrative/attendance-validation" },
            { name: "Émargements validés", href: "/admin/administrative/attendance-validated" }
        ]
    }, {
        name: 'Admin',
        menu: true,
        right: TokenRight.ADMIN,
        href: '/admin/admin',
        items: [
            { name: "Reporting", href: "/admin/admin/report" },
            { name: "Contact", href: "/admin/admin/contact" },
        ]
    }, {
        name: 'Marketing',
        menu: true,
        right: TokenRight.MARKETING,
        href: '/admin/market',
        items: [
            // { name: "Lead manager", href: "/admin/market/lead" },
            { name: "Data", href: "/admin/market/data" }
        ]
    }, {
        name: 'Pédagogique',
        menu: true,
        right: TokenRight.TRAINER,
        href: '/admin/pedagogical',
        items: [
            { name: "Corrections", href: "/admin/pedagogical/corrections" },
            { name: "Stagiaires", href: "/admin/pedagogical/contacts" }
        ]
    }, {
        name: 'Stagiare',
        right: TokenRight.TRAINER,
        href: '/admin/contact',
        items: []
    }
] satisfies {
    name: string;
    right: TokenRight;
    href: string;
    menu?: boolean;
    items: {
        name: string;
        href: string
    }[]
}[]


export function adminRoutes(...rights: TokenRight[]) {
    return ADMIN_ROUTES.filter(r => rights.indexOf(r.right) !== -1)
}

export function adminMenuRoutes(...rights: TokenRight[]) {
    return adminRoutes(...rights).filter(r => r.menu)
}

/**
 * generate url to login
 */
export const navGuardLoginUrl = function (target = '') {
    if (window.location.pathname.split('?')[0] !== '/login')
        if (target && target !== '/' && !PUBLIC_ROUTES.some(s => target.startsWith(`/${s}`)))
            return `/login?target=${target.replaceAll(/\?.*/g, '')}`
        else
            return '/login'
}

export const navGuard = function (to: RouteLocationNormalized, from: RouteLocationNormalized): string | void {
    if (import.meta.client) {

        if (useRuntimeConfig().public.maintenance)
            if (to.path !== '/maintenance') return '/maintenance'
            else return

        const storeAccount = useNuxtApp().$store?.account
        if (!storeAccount) { return; }

        if (to.name === '404')
            setPageLayout("error")
        else if (PUBLIC_ROUTES.indexOf(to.name as string) !== -1)
            setPageLayout("public")
        else if (to.fullPath.startsWith('/admin'))
            setPageLayout("admin")
        else if (to.fullPath.startsWith('/doc'))
            setPageLayout("blank")
        else
            setPageLayout("default")

        // public route
        if (PUBLIC_ROUTES.indexOf(to.name as string) !== -1) {
            localStorage?.removeItem('state')
            localStorage?.clear()
            storeAccount.$reset()
        }

        // authenticated route
        else {

            issueReport.reset() // reset log history

            if (!storeAccount.token) {
                console.warn(`Unauthorize access to ${to.fullPath}: need to be authenticated`)
                return navGuardLoginUrl(to.path)
            }

            if (to.fullPath.startsWith('/admin')) {
                if (!storeAccount.isInternal) {
                    console.warn(`Unauthorize access to ${to.fullPath}: need to be admin`)
                    return '/'
                } else {
                    const rr = ADMIN_ROUTES.find(r => to.fullPath.startsWith(r.href))
                    if (!rr || storeAccount.token.accessDecoded.rights.indexOf(rr.right) === -1) {
                        if (rr)
                            console.warn(`Unauthorize access to ${to.fullPath}: need right ${TokenRight[rr.right]}`)
                        else
                            console.warn(`Unauthorize access to ${to.fullPath}: admin route not configured`)
                        return '/'
                    }
                }
            }

        }
    }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
    const redirect = (await navGuard(to, from));
    if (redirect)
        window.location.replace(redirect)
})