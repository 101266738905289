<template>
  <div class="contact-icon" :title="title">
    <div v-if="initials" :style="{ 'background-color': color }">
      <span>
        {{ initials }}
      </span>
    </div>
    <t-user-icon v-else />
  </div>
</template>


<script lang="ts">
import { UserIcon } from '@heroicons/vue/24/outline'

import type { IUser } from '@/api/myedvenn/user'

export default defineComponent({
  components: {
    't-user-icon': UserIcon
  },
  props: {
    user: Object as PropType<IUser>,
    userId: Number,

    size: {
      type: Number,
      default: () => 2.5
    }
  },
  setup(props) {
    const { $store } = useNuxtApp()

    const contact = ref<IUser>(null)

    watch(() => props, async _props => {
      if (_props.user) {
        if (Object.keys(_props.user).length === 1 && "id" in _props.user)
          contact.value = await $store.trainee.getContact(_props.user.id)
        else
          contact.value = props.user
      } else if (props.userId){
        contact.value = await $store.trainee.getContact(_props.userId)
      }
    }, { immediate: true })

    return {
      initials: computed(() =>
        `${contact.value?.firstname ? contact.value.firstname[0] : ''}${contact.value?.lastname ? contact.value.lastname[0] : ''}`
      ),
      color: computed(() =>
        contact.value?.color || '#f3f4f6'
      ),
      title: computed(() =>
        `${contact.value?.firstname ? contact.value.firstname : ''} ${contact.value?.lastname ? contact.value.lastname : ''}`
      )
    }
  }
})
</script>

<style>
.contact-icon {
  @apply relative;

  >div {
    @apply rounded-full opacity-30 flex;
    height: calc(v-bind(size) * 1rem);
    width: calc(v-bind(size) * 1rem);

    >span {
      @apply text-lg font-bold text-white self-center w-full text-center;
      font-size: calc(v-bind(size / 2) * 1rem);
      text-shadow: -1px 0 gray, 0 1px gray, 1px 0 gray, 0 -1px gray;
    }
  }

  >svg {
    @apply p-1 text-white bg-gray-300 rounded-full;
    height: calc(v-bind(size) * 1rem);
    width: calc(v-bind(size) * 1rem);
  }
}
</style>
