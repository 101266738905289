
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46404_93EQT9YOe45IMeta } from "/myedvenn/pages/[...404].vue?macro=true";
import { default as accountcgNCD5hmVzMeta } from "/myedvenn/pages/account.vue?macro=true";
import { default as contactMHEjAiwa3hMeta } from "/myedvenn/pages/admin/admin/contact.vue?macro=true";
import { default as indexRSFixR0mo5Meta } from "/myedvenn/pages/admin/admin/index.vue?macro=true";
import { default as reportFHnuPIG1K6Meta } from "/myedvenn/pages/admin/admin/report.vue?macro=true";
import { default as attendance_45_91status_93nDPSdVpFEaMeta } from "/myedvenn/pages/admin/administrative/attendance-[status].vue?macro=true";
import { default as indexLPSL0LDxk9Meta } from "/myedvenn/pages/admin/administrative/index.vue?macro=true";
import { default as _91id_935OFDNTxMsQMeta } from "/myedvenn/pages/admin/contact/[id].vue?macro=true";
import { default as datarfIHIoPQXmMeta } from "/myedvenn/pages/admin/market/data.vue?macro=true";
import { default as indexzvgibSL00FMeta } from "/myedvenn/pages/admin/market/index.vue?macro=true";
import { default as leadpeIpgb2xB0Meta } from "/myedvenn/pages/admin/market/lead.vue?macro=true";
import { default as contactsd9atXJik4TMeta } from "/myedvenn/pages/admin/pedagogical/contacts.vue?macro=true";
import { default as correctionsn16xhDNrunMeta } from "/myedvenn/pages/admin/pedagogical/corrections.vue?macro=true";
import { default as indexsK22z5YVo4Meta } from "/myedvenn/pages/admin/pedagogical/index.vue?macro=true";
import { default as _91attendanceId_93DHXaBa68wDMeta } from "/myedvenn/pages/doc/attendance/[attendanceId].vue?macro=true";
import { default as indexTFZplVLTX2Meta } from "/myedvenn/pages/index.vue?macro=true";
import { default as login5dXPFO301tMeta } from "/myedvenn/pages/login.vue?macro=true";
import { default as maintenancerPLUXnQVAEMeta } from "/myedvenn/pages/maintenance.vue?macro=true";
import { default as password_resetBM8o8ZmaLxMeta } from "/myedvenn/pages/password_reset.vue?macro=true";
import { default as attendance7Ius3w9yMjMeta } from "/myedvenn/pages/training/[id]/attendance.vue?macro=true";
import { default as calendarcLOju4kISdMeta } from "/myedvenn/pages/training/[id]/calendar.vue?macro=true";
import { default as contractac12tpG7WRMeta } from "/myedvenn/pages/training/[id]/contract.vue?macro=true";
import { default as indexQSMAK5gz06Meta } from "/myedvenn/pages/training/[id]/index.vue?macro=true";
import { default as _91id_93mhhjjPFOSuMeta } from "/myedvenn/pages/training/[id].vue?macro=true";
import { default as welcome4xGeziDxZzMeta } from "/myedvenn/pages/welcome.vue?macro=true";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    component: () => import("/myedvenn/pages/[...404].vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/myedvenn/pages/account.vue")
  },
  {
    name: "admin-admin-contact",
    path: "/admin/admin/contact",
    component: () => import("/myedvenn/pages/admin/admin/contact.vue")
  },
  {
    name: "admin-admin",
    path: "/admin/admin",
    component: () => import("/myedvenn/pages/admin/admin/index.vue")
  },
  {
    name: "admin-admin-report",
    path: "/admin/admin/report",
    component: () => import("/myedvenn/pages/admin/admin/report.vue")
  },
  {
    name: "admin-administrative-attendance-status",
    path: "/admin/administrative/attendance-:status()",
    component: () => import("/myedvenn/pages/admin/administrative/attendance-[status].vue")
  },
  {
    name: "admin-administrative",
    path: "/admin/administrative",
    component: () => import("/myedvenn/pages/admin/administrative/index.vue")
  },
  {
    name: "admin-contact-id",
    path: "/admin/contact/:id()",
    component: () => import("/myedvenn/pages/admin/contact/[id].vue")
  },
  {
    name: "admin-market-data",
    path: "/admin/market/data",
    component: () => import("/myedvenn/pages/admin/market/data.vue")
  },
  {
    name: "admin-market",
    path: "/admin/market",
    component: () => import("/myedvenn/pages/admin/market/index.vue")
  },
  {
    name: "admin-market-lead",
    path: "/admin/market/lead",
    component: () => import("/myedvenn/pages/admin/market/lead.vue")
  },
  {
    name: "admin-pedagogical-contacts",
    path: "/admin/pedagogical/contacts",
    component: () => import("/myedvenn/pages/admin/pedagogical/contacts.vue")
  },
  {
    name: "admin-pedagogical-corrections",
    path: "/admin/pedagogical/corrections",
    component: () => import("/myedvenn/pages/admin/pedagogical/corrections.vue")
  },
  {
    name: "admin-pedagogical",
    path: "/admin/pedagogical",
    component: () => import("/myedvenn/pages/admin/pedagogical/index.vue")
  },
  {
    name: "doc-attendance-attendanceId",
    path: "/doc/attendance/:attendanceId()",
    component: () => import("/myedvenn/pages/doc/attendance/[attendanceId].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/myedvenn/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/myedvenn/pages/login.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/myedvenn/pages/maintenance.vue")
  },
  {
    name: "password_reset",
    path: "/password_reset",
    component: () => import("/myedvenn/pages/password_reset.vue")
  },
  {
    name: _91id_93mhhjjPFOSuMeta?.name,
    path: "/training/:id()",
    component: () => import("/myedvenn/pages/training/[id].vue"),
    children: [
  {
    name: "training-id-attendance",
    path: "attendance",
    component: () => import("/myedvenn/pages/training/[id]/attendance.vue")
  },
  {
    name: "training-id-calendar",
    path: "calendar",
    component: () => import("/myedvenn/pages/training/[id]/calendar.vue")
  },
  {
    name: "training-id-contract",
    path: "contract",
    component: () => import("/myedvenn/pages/training/[id]/contract.vue")
  },
  {
    name: "training-id",
    path: "",
    component: () => import("/myedvenn/pages/training/[id]/index.vue")
  }
]
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/myedvenn/pages/welcome.vue")
  }
]