import { Request, UnaryInterceptor, UnaryResponse, StatusCode } from 'grpc-web';
import type { Message } from 'google-protobuf'
import { useNuxtApp, type NuxtApp } from '#app'

import { myedvenn } from '@/api/index'
import { rootEmitter } from '@/plugins/emitter'
import { ErrorCode } from '@/api/myedvenn/common';

const HEADER_ACCESS = "authorization"
const HEADER_REFRESH = "authorization-refresh"

class AuthUnaryInterceptor implements UnaryInterceptor<any, any> {
    private _store: NuxtApp['$store']['account']

    get store() {
        if (!this._store)
            this._store = useNuxtApp().$store.account
        return this._store
    }

    async intercept(request: Request<Message, Message>, invoker: (request2: Request<any, any>) => Promise<UnaryResponse<any, any>>) {
        const method = request.getMethodDescriptor().getName();
        
        // add token on request
        const token = this.store.$state.token;
        let refresh = false
        if (token && (token.refresh || token.access)) {
            // if access token is expired, use refresh token
            if (token.accessDecoded && token.accessDecoded.exp && Date.now() > ((token.accessDecoded.exp - 2) * 1000)) {
                request.getMetadata()[HEADER_REFRESH] = 'Bearer ' + token.refresh;
                refresh = true
            } else {
                request.getMetadata()[HEADER_ACCESS] = 'Bearer ' + token.access;
            }
        } else {
            console.info(method, 'no token')
        }

        try {
            let response: UnaryResponse<any, any> = null

            try {
                response = await invoker(request)
            } catch (e: any) {
                // if we use access token but receive a token expired error, retry with refresh token
                const code = parseInt((e?.metadata && e.metadata['error-code']) || `${e.code}` || '2')
                if (code === ErrorCode.UNAUTHORIZED_LOGOUT && !refresh) {
                    delete request.getMetadata()[HEADER_ACCESS]
                    request.getMetadata()[HEADER_REFRESH] = 'Bearer ' + token.refresh;
                    response = await invoker(request)
                    refresh = true
                } else {
                    throw e
                }
            }

            console.info(`[API] ${method}`)
            console.debug('\treq: ', ifToObject(request.getRequestMessage()))
            console.debug('\trep: ', ifToObject(response.getResponseMessage()))

            // refresh token with response
            const metaRep = response.getMetadata();

            if (!this.store.token && metaRep[HEADER_ACCESS] && metaRep[HEADER_REFRESH])
                this.store.setToken(metaRep[HEADER_ACCESS], metaRep[HEADER_REFRESH]);
            else if (refresh && metaRep[HEADER_ACCESS])
                this.store.setToken(metaRep[HEADER_ACCESS]);

            return response;
        } catch (e: any) {

            // TODO: translate ErrorCode.
            const code = parseInt((e?.metadata && e.metadata['error-code']) || `${e.code}` || '2')
            const codeGroup = code % 1000

            console.info(`[API] ${method} throw an error.`)
            console.warn('\treq: ', ifToObject(request.getRequestMessage()))
            console.warn('\terror: ', codeGroup, code, e?.message)

            if (code === ErrorCode.UNAUTHORIZED_LOGOUT) {
                rootEmitter.notifyWarn("Session expirée")
                this.store.logoutTo()
            } else if (codeGroup >= 400 && codeGroup < 500) {
                rootEmitter.notifyWarn(e)
            } else if (code === StatusCode.UNAVAILABLE) {
                rootEmitter.notifyError("Service temporairement indisponible")
            } else if (code === StatusCode.UNKNOWN || codeGroup >= 500) {
                rootEmitter.notifyError(e)
            }

            throw e;
        }
    }
}

function ifToObject(obj: any) {
    return (obj && obj.toObject && typeof obj.toObject === 'function') ? obj.toObject() : obj
}

class ApiHttp {

    get store() {
        const nuxtA = useNuxtApp()
        return nuxtA.$store //this.nuxtApp.$store
    }

    fetch(input: RequestInfo, options?: RequestInit) {

        const headers = {} as any;
        const token = this.store.account.token;
        if (token && token.accessDecoded) {
            if (token.accessDecoded.exp && Date.now() > (token.accessDecoded.exp * 1000))
                headers[HEADER_REFRESH] = 'Bearer ' + token.refresh;
            else
                headers[HEADER_ACCESS] = 'Bearer ' + token.access;
        }

        return fetch(input, { ...options, ...{ headers } }).then(async rep => {
            if (rep.status >= 300) throw new Error(await rep.text())
            if (headers[HEADER_REFRESH])
                this.store.account.setToken(rep.headers.get(HEADER_ACCESS))
            return rep
        })
    }

    // async upload(f: IFileLink, data: any) {
    //     if (data instanceof File) {
    //         const formData = new FormData();
    //         formData.set('file', data)
    //         formData.set('link', JSON.stringify(f))
    //         await this.fetch('file/upload', {
    //             method: 'POST',
    //             body: formData
    //         })
    //     }
    // }
}

export default defineNuxtPlugin(() => {
    const grpcClientOptions = {
        unaryInterceptors: [new AuthUnaryInterceptor()]
    }

    const apiBaseUrl = useRuntimeConfig().public.apiBaseUrl || window.location.origin

    return {
        provide: {
            api: {
                account: new myedvenn.accountClient.AccountServiceClient(apiBaseUrl, grpcClientOptions),
                user: new myedvenn.userClient.UserServiceClient(apiBaseUrl, grpcClientOptions),
                contact: new myedvenn.contactClient.ContactServiceClient(apiBaseUrl, grpcClientOptions),

                training: new myedvenn.trainingClient.TrainingServiceClient(apiBaseUrl, grpcClientOptions),
                attendance: new myedvenn.attendanceClient.AttendanceServiceClient(apiBaseUrl, grpcClientOptions),
                payment: new myedvenn.paymentClient.PaymentServiceClient(apiBaseUrl, grpcClientOptions),
                document: new myedvenn.documentClient.DocumentServiceClient(apiBaseUrl, grpcClientOptions),
                comment: new myedvenn.commentClient.CommentServiceClient(apiBaseUrl, grpcClientOptions),

                report: new myedvenn.reportClient.ReportServiceClient(apiBaseUrl, grpcClientOptions),
                leads: new myedvenn.reportClient.LeadServiceClient(apiBaseUrl, grpcClientOptions),

                http: new ApiHttp()
            }
        }
    }
})