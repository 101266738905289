<template>
  <Teleport to="#__nuxt">
    <TransitionRoot as="template" :show="isOpen">
      <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto max-w-[100vw]" @close="isOpen = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
              <div v-if="opt.crossClose" class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" @click="isOpen = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div v-if="opt" class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">{{ opt.title }} </DialogTitle>
                    <slot name="content">
                      <div class="mt-2 text-justify">
                        <p v-for="desc in opt.content" class="text-sm text-gray-500">{{ desc }}&nbsp;</p>
                      </div>
                    </slot>
                  </div>
                </div>
              </div>
              <div v-if="opt" class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <slot name="buttons" :close="close">
                  <button v-for="button in opt.buttons" @click="close(button.value)" :class="['button', button.class]">{{ button.title }}</button>
                </slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>

<script lang="ts">
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

type ButtonClass = 'button-default' | 'button-red'

export type ModalOpt = {

  title: string;

  /**
   * Define content
   * or
   * use: <template v-slot:content>
   */
  content?: string[];

  /**
   * Define buttons with return value
   * or
   * use: <template v-slot:buttons="{close}">
   */
  buttons?: {
    title: string;
    value: any;
    class?: ButtonClass | string;
  }[];

  crossClose?: boolean
}

export default {
  components: {
    Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon
  },
  props: {
    opt: Object as PropType<ModalOpt>
  },
  emits: ['close'],
  setup(props, { emit }) {
    const isOpen = computed({
      get() { return !!props.opt },
      set(v: boolean) { if (v === false) emit('close') }
    });

    const opt = computed<ModalOpt>(() => {
      if (!props.opt) return
      return {
        title: props.opt.title,
        content: props.opt.content,
        buttons: props.opt.buttons || [
          { title: 'Ok', value: true, class: 'button-red py-1 px-2 ml-2' },
          { title: 'Annuler', value: false, class: 'button-default py-1 px-2' }
        ],
        crossClose: props.opt.crossClose || false
      }
    })

    return {
      isOpen,
      opt,

      close(val) {
        emit('close', val)
      }
    };
  }
};
</script>