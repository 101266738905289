import { PiniaPluginContext } from 'pinia'
import { useStoreAccount } from "@/stores/store.account";
import { useStoreTrainee } from "@/stores/store.trainee";
import { useStoreDisplay } from "@/stores/store.display";
import { useStoreSettings } from "@/stores/store.settings";
import { navGuard } from '@/middleware/public.global'

import { useNuxtApp } from '#app'

const debounce = function (func, wait) {
    let timeout
    return (e) => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(func, wait, e)
    }
}

function MyPiniaPlugin(ctx: PiniaPluginContext) {

    if (import.meta.client) {

        if (ctx.store.$id === 'account') {
            ctx.store.$subscribe((mutation) => {
                // log and sync state with localstorage
                if (!mutation.events) {
                    console.info(`[STORE] Mutate ${mutation.storeId}`)
                } else {
                    const evts = (Array.isArray(mutation.events) ? mutation.events : [mutation.events])
                        .map(e => ([`${e.type} ${e.key}`, e.newValue === undefined ? 'undefined' : JSON.parse(JSON.stringify(e.newValue))]))
                    console.groupCollapsed(`[STORE] Mutate ${mutation.storeId}:`, evts.map(_e => _e[0]).join(','))
                    for (const _e of evts) console.log('\t', _e[0] + ':', _e[1])
                    console.groupEnd()
                }
                localStorage.setItem('state', JSON.stringify(ctx.store.$state))
            })

        } else if (ctx.store.$id === 'display') {
            const displayStore = ctx.store as ReturnType<typeof useStoreDisplay>
            window.addEventListener(
                'resize',
                debounce(() => displayStore.resize(window.innerWidth), 200),
                false
            )
        } else if (ctx.store.$id === 'settings') {
            ctx.store.$subscribe(() => localStorage.setItem('settings', JSON.stringify(ctx.store.$state)))
        } else if (ctx.store.$id === 'trainee') {
            ctx.store.$subscribe(() => localStorage.setItem('trainee', JSON.stringify(ctx.store.$state)))
        }

    }

    // inject $axios / $api in store
    if (!ctx.app.$nuxt.$axios || !ctx.app.$nuxt.$api || !ctx.app.$nuxt.$router) throw new Error('$axios or $api undefined')

    return {
        $axios: ctx.app.$nuxt.$axios,
        $api: ctx.app.$nuxt.$api,
        $router: ctx.app.$nuxt.$router
    }
}

export type PiniaCustomProp = {
    $axios: ReturnType<typeof useNuxtApp>['$axios']
    $api: ReturnType<typeof useNuxtApp>['$api']
    $router: ReturnType<typeof useNuxtApp>['$router']
}

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.hook('app:mounted', async () => {
        try {
            await useStoreAccount().refresh()
        } finally {
            const redirect = await navGuard(useRoute(), null)
            if (redirect)
                window.location.replace(redirect)
        }
    });

    (nuxtApp.$pinia as any).use(MyPiniaPlugin)


    return {
        provide: {
            get store() {
                return {
                    account: useStoreAccount(),
                    display: useStoreDisplay(),
                    settings: useStoreSettings(),
                    trainee: useStoreTrainee()
                }
            }
        }
    }
})