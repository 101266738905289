import type { Router, RouteLocationNormalizedLoaded } from '#vue-router'

export default defineNuxtPlugin(() => ({
    provide: {
        // get $router() {
        //     return useRouter()
        // },

        // get $route() {
        //     return useRouter()
        // },

        get routeParser() {
            return generateParser(useRouter())
        },

        watchRoute: (call: (r: RouteLocationNormalizedLoaded) => void) => {
            const route = useRoute()
            const currentPath = route.path
            watch(
                route,
                r => { if (r.path === currentPath) call(r) },
                { flush: 'post' }
            )
            call(route)
        },
    }
}))

function generateParser(_router: Router) {
    function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array?: false, optional?: false): T
    function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array: true, optional?: false): T[]
    function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array: false, optional: true): T | undefined
    function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array: true, optional: true): T[] | undefined
    function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params = false, array = false, optional = false) {
        const valueParent = params ? _router.currentRoute.value.params : _router.currentRoute.value.query

        try {
            if (key in valueParent) {
                const value = valueParent[key] as string | string[]
                if (array) {
                    if (Array.isArray(value))
                        return value.map(v => parser(v)).filter(v => v !== undefined)
                    else
                        return [parser(value)]
                } else {
                    if (typeof value === 'string')
                        return parser(value)
                }
            }

            if (optional)
                return array ? [] : undefined

            // _router.go404()
            throw new Error(`wrong url ${params ? 'params' : 'query'}: ${JSON.stringify(valueParent)}`)
        } catch (e: any) {
            // _router.go404()
            throw e
        }
    }

    function parserOptArray<T>(parser: (value: string) => T | undefined, params: boolean) {

        function getOpt(key: string): T | undefined
        function getOpt(key: string, fallback: T): T
        function getOpt(key: string, fallback?: T) {
            const result = getQueryOrParams(key, parser, params, false, true)
            if (result === undefined && fallback !== undefined)
                return fallback
            return result
        }

        function getArrayOpt(key: string): T[] | undefined
        function getArrayOpt(key: string, fallback: T[]): T[]
        function getArrayOpt(key: string, fallback?: T[]) {
            const result = getQueryOrParams(key, parser, params, true, true)
            if (result === undefined && fallback !== undefined)
                return fallback
            return result
        }

        return {
            /** get required value */
            get(key: string) {
                return getQueryOrParams(key, parser, params)
            },
            /** get optional value */
            getOpt,
            /** get required values */
            getArray(key: string) {
                return getQueryOrParams(key, parser, params, true, false)
            },
            /** get optional values */
            getArrayOpt
        }
    }

    function parserQueryOrParams(params: boolean) {
        return {
            int: parserOptArray(
                v => {
                    const vv = parseInt(v)
                    if (!isNaN(vv)) return vv
                },
                params
            ),
            bool: parserOptArray(
                v => v === '1' || v === 'true',
                params
            ),
            string: parserOptArray(
                v => v,
                params
            )
        }
    }

    return {
        query: parserQueryOrParams(false),
        params: parserQueryOrParams(true)
    }
}