import { acceptHMRUpdate, defineStore } from 'pinia'

import { IUser } from '~/api/myedvenn/user'
import { ITraining } from '~/api/myedvenn/training';

type TraineeState = {
    trainings?: Record<number, ITraining>;
    contacts: Record<number, IUser>;
}

export const useStoreTrainee = defineStore('trainee', {

    state: () => {
        const localStorageState = JSON.parse(localStorage?.getItem('trainee') || '{}');
        console.debug(`[trainee]: localStorageState reload.`, localStorageState)

        return {
            trainings: undefined,
            contacts: {},
            ...localStorageState
        } as TraineeState

    },

    getters: {
        // trainings
        allTrainings: state => Object.values(state.trainings || {}),
        // trainings parts and waitingAttendance loaded
        allTrainingsLoaded: state => Object.values(state.trainings || {})
            .every(t => t.parts !== undefined),
        // training ids with waiting attendance
        allTrainingsIdWaiting: state => Object.values(state.trainings || {})
            .map(t => t.state.waitingAttendance ? t.id : undefined)
            .filter(tid => tid !== undefined)
    },

    actions: {

        async refresh() {

            const loadingParts = []
            this.trainings = await this.$api.training.find({})
                .then(v => (v.values || []))
                .then(trainings => trainings.reduce<Record<number, ITraining>>((tot, t) => {

                    loadingParts.push(
                        this.loadTrainingPart(t.id)
                    );

                    tot[t.id] = t;
                    return tot
                }, {}))

            Promise.all(loadingParts)
        },

        async getContact(contactId: number) {
            const getContactCached = getCache<number, IUser>(
                'contactCache',
                {
                    get: _contactId => this.$state.contacts[_contactId],
                    set: (_contactId, _contact) => { this.$state.contacts[_contactId] = _contact }
                },
                async contactId => {
                    const us = await this.$api.user.find({ criterias: [{ field: 'id', value: `${contactId}` }], limit: 1 })
                    if (us.values.length) return us.values[0]
                    console.debug('user not found', contactId)
                    return null
                }
            )

            return getContactCached(contactId)
        },

        async loadTrainingPart(id: number) {
            const _training = await this.$api.training.get({
                id,
                relations: ['state', 'parts.modules.comments', 'parts.modules.sections.assigns', 'parts.modules.periods']
            })
            this.trainings[id] = _training
        }
    }
})

const cache: Record<string, Record<string, any>> = {}

function getCache<Arg, Resource>(
    cacheId: string,
    store: { get(a: Arg): Resource; set(a: Arg, r: Resource) },
    callApi: (a: Arg) => Promise<Resource>
) {

    return (a: Arg) => {
        const _store = store.get(a)

        const cacheResourceId = JSON.stringify(a)
        if (!cache[cacheId]) cache[cacheId] = {}
        let _cache = cache[cacheId][cacheResourceId]

        if (_store !== undefined) return _store

        if (!_cache) {
            _cache = callApi(a)
                .then(c => {
                    store.set(a, c)
                    return c
                }).catch(e => {
                    // console.error(e)
                    return null
                })
            cache[cacheId][cacheResourceId] = _cache
        }
        return _cache
    }
}

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreTrainee, import.meta.hot))
}