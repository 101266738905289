<template>
  <footer class="bg-gray-800" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto pb-2 pt-8 lg:pt-12 px-4 sm:px-6 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">

        <div>
          <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
            Notre raison d'être
          </h3>
          <p class="mt-4 text-base text-gray-200">
            Vous garantir de développer et pérenniser votre activité en vous permettant d’acquérir des connaissances et des compétences immédiatement mobilisables répondant à des besoins de marché.
          </p>
        </div>

        <div class="grid grid-cols-1 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8 mt-12 xl:mt-0">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                gagnez de nouveaux marchés
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in navigation.newMarket" :key="item.name">
                  <a :href="item.href" class="text-base text-gray-300 hover:text-white" target="_blank">
                    {{ item.name }}
                    <ArrowTopRightOnSquareIcon class="ml-1 w-4 h-4 inline opacity-30" />
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Marketing et pilotage
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in navigation.marketingManagement" :key="item.name">
                  <a :href="item.href" class="text-base text-gray-300 hover:text-white" target="_blank">
                    {{ item.name }}
                    <ArrowTopRightOnSquareIcon class="ml-1 w-4 h-4 inline opacity-30" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
        <div class="flex space-x-6 md:order-2">
          <a v-for="item in navigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-300" target="_blank">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          &copy; 2020 Edvenn. All rights reserved. <span class="text-transparent">{{ version }}</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { h } from 'vue'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'

const navigation = {
  newMarket: [
    { name: 'Traduction financière', href: 'https://edvenn.com/nos-formations/formation-a-la-traduction-financiere/' },
    { name: 'Traduction juridique', href: 'https://edvenn.com/nos-formations/formation-traducteur-juridique/' },
    { name: 'Traduction médicale', href: 'https://edvenn.com/nos-formations/formation-traducteur-medical/' },
    { name: 'Traduction technique', href: 'https://edvenn.com/nos-formations/formation-traducteur-technique/' }
  ],
  marketingManagement: [
    { name: 'Développer votre activité', href: 'https://edvenn.com/nos-formations/formation-developpement-entreprise-traduction/' },
    { name: 'Développez vos ventes', href: 'https://edvenn.com/nos-formations/vendre-et-promouvoir-ses-services-de-traduction/' },
    { name: 'Pilotez et optimisez votre activité', href: 'https://edvenn.com/nos-formations/augmentez-vos-revenus-grace-a-une-gestion-optimisee/' }
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/school/edvenn',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 64 64' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M25,44h-5V26h5V44z M22.485,24h-0.028C20.965,24,20,22.888,20,21.499C20,20.08,20.995,19,22.514,19c1.521,0,2.458,1.08,2.486,2.499 C25,22.887,24.035,24,22.485,24z M44,44h-5v-9c0-3-1.446-4-3-4c-1.445,0-3,1-3,4v9h-5V26h5v3c0.343-0.981,1.984-3,5-3c4,0,6,3,6,8 V44z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/edvenn2',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd'
            }),
          ]),
      }),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/edvenn',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
            })
          ])
      })
    }
  ]
}

export default defineComponent({
  components: {
    ArrowTopRightOnSquareIcon
  },
  setup() {
    return {
      navigation,
      version: useRuntimeConfig().public.version || ''
    }
  }
})
</script>