import { TinyEmitter } from "tiny-emitter"

import { Notif, NotifLevel } from '@/types'
import { mailtoSupport } from "@/util"

import { ModalOpt } from "~/components/Modal.vue"

class Emitter extends TinyEmitter {
    modal(v: ModalOpt) {
        return new Promise(r => {
            this.emit('modal', {
                ...v,
                onClose: r
            })
        })
    }

    notify(notif: Notif | string) {
        this.emit('notify', {
            notification: {
                group: "error",
                ...(typeof notif === 'string' ? { title: notif } : notif)
            }
        })
    }

    notifyError(e: Error | string, title = '') {
        this._notifyError(e, NotifLevel.error, title)
    }

    notifyWarn(e: Error | string, title = '') {
        this._notifyError(e, NotifLevel.warn, title)
    }

    notifyInfo(e: Error | string, title = '') {
        this._notifyError(e, NotifLevel.info, title)
    }

    private _notifyError(e: Error | string, level: NotifLevel, title = 'Erreur Technique') {
        const eMessage = typeof e === 'string' ? e : (e?.message as string || '')
        const notif: Notif = {
            title,
            text: eMessage,
            level
        }
        const errorId = /^\[([0-9]*)\]/.exec(eMessage)
        if (errorId) {
            notif.text = eMessage.replace(`[${errorId[1]}] `, '')
            notif.link = mailtoSupport(useNuxtApp()?.$store?.account?.account?.email, e)
            notif.linkText = 'Signaler un problème'
        }
        this.notify(notif)
    }

}

export const rootEmitter = new Emitter()


function _throwError(err: Error, info?: string) {
    console.warn('error', err, 'info', info)

    const route = useRoute()
    route.params.error = err?.message
    route.meta.layout = "error"
}

export function throwFatalError(err: Error, info?: string) {
    _throwError(err, info)
    throw err
}

export default defineNuxtPlugin(ctx => {

    ctx.vueApp.config.errorHandler = (error: any, instance, info) => {
        if (info === "setup function")
            _throwError(error, info)
    }

    return {
        provide: {
            rootEmitter
        }
    }
})